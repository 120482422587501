import { List } from "@arsea/component";
import { TUserManager } from "@arsea/graphql";
import { UserOrganisationManagerListQuery } from "../queries/UserOrganisationManagerListQuery";
import UserOrganisationManagerListItem from "./UserOrganisationManagerListItem";
import { useCallback } from "react";

const UserOrganisationManagerList = ({onUpdate, onArchive}: UserOrganisationManagerListProps) => {
    
    const renderRow = useCallback((row: TUserManager) => {
        return (
            <UserOrganisationManagerListItem
                key={row.id}
                {...row}
                onUpdate={onUpdate}
                onArchive={onArchive}
            />
        );
    }, []);

    return (
        <List
            id={USER_ORGANISATION_MANAGER_LIST_ID}
            selection={false}
            search={false}
            GQLRequest={UserOrganisationManagerListQuery}
            isUrlParamsState
            defaultFilter={{
                page: 0,
                pageSize: 10,
                data: {
                    dateArchived_isNull: true,
                },
            }}
            onEnableRequest={(params) => params?.instance?.variables?.sort}
            onRenderRow={renderRow}
        />
    );
};

export default UserOrganisationManagerList;

export const USER_ORGANISATION_MANAGER_LIST_ID =
    "App.Arsea.User.Organisation.Managers.List.Id";

type UserOrganisationManagerListProps = {
    onUpdate?: (managerId: number) => void;
    onArchive?: (managerId: number) => void;
};
