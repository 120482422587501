import { Outlet } from "react-router-dom";
import { useNotificationWebsocket } from "@arsea/notification";

const ArseaContainer = () => {
    useNotificationWebsocket();

    return <Outlet />;
};

export default ArseaContainer;
