import {
    Button,
    ControlList,
    Dialog,
    useListGraphqlQuery,
    useRuntimeEnv,
    useTranslations,
} from "@arsea/component";
import moment from "moment";
import { useState } from "react";
import UserOrganisationManagerList, {
    USER_ORGANISATION_MANAGER_LIST_ID,
} from "../components/UserOrganisationManagerList";
import UserOrganisationManagerListFilter from "../components/UserOrganisationManagerListFilter";
import { useArchiveMutation } from "../hooks/useArchiveMutation";
import { useManagerControl } from "../hooks/useManagerControl";
import { useParams } from "react-router-dom";
import { useUserOrganisationDetailsQuery } from "../../../hooks/useUserOrganisationDetailsQuery";

const UserOrganisationManagerContainer = () => {
    const [managerId, setManagerId] = useState<number | undefined>(undefined);
    const [wantToCreate, setWantToCreate] = useState(false);
    const [wantToUpdate, setWantToUpdate] = useState(false);
    const [wantToArchive, setWantToArchive] = useState(false);
    const { id } = useParams();
    
    const { data } = useUserOrganisationDetailsQuery(parseInt(id), true);

    const [
        NEW,
        UPDATE_DIALOG_TITLE,
        ADD_DIALOG_TITLE,
        ARCHIVE_CONFIRMATION_TITLE,
        ARCHIVE_CONFIRMATION_DESCRIPTION,
        CONFIRM,
    ] = useTranslations(i18n);
    const { invalidateQuery } = useListGraphqlQuery(
        USER_ORGANISATION_MANAGER_LIST_ID
    );

    const managerControl = useManagerControl(!!managerId);
    const archiveMutation = useArchiveMutation();

    const handleCreated = () => {
        invalidateQuery();
        setWantToCreate(false);
    };

    const handleUpdated = () => {
        invalidateQuery();
        setManagerId(undefined);
        setWantToUpdate(false);
    };

    const handleArchive = () => {
        archiveMutation.mutate(
            {
                data: {
                    id: managerId,
                    dateArchived: moment().format(),
                },
            },
            {
                onSuccess: () => {
                    invalidateQuery();
                    setManagerId(undefined);
                    setWantToArchive(false);
                },
                onError: () => {
                    invalidateQuery();
                    setManagerId(undefined);
                    setWantToArchive(false);
                },
            }
        );
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-4 items-center justify-between">
                <UserOrganisationManagerListFilter
                    listId={USER_ORGANISATION_MANAGER_LIST_ID}
                />
                {data?.canAddManager && (
                    <Button
                        className="btn-primary"
                        label={NEW}
                        onClick={() => setWantToCreate(true)}
                    />
                )}
            </div>

            <UserOrganisationManagerList
                onUpdate={(managerId) => {
                    setManagerId(managerId);
                    setWantToUpdate(true);
                }}
                onArchive={(managerId) => {
                    setManagerId(managerId);
                    setWantToArchive(true);
                }}
            />

            <Dialog
                title={managerId ? UPDATE_DIALOG_TITLE : ADD_DIALOG_TITLE}
                open={managerId ? wantToUpdate : wantToCreate}
                onClose={() => {
                    if (managerId) {
                        setManagerId(undefined);
                        setWantToUpdate(false);
                        return;
                    }
                    setWantToCreate(false);
                }}
            >
                <ControlList
                    {...managerControl}
                    defaultValue={{
                        id: managerId,
                    }}
                    onSubmited={() => {
                        if (managerId) {
                            handleUpdated();
                            return;
                        }
                        handleCreated();
                    }}
                    onCancel={() => {
                        if (managerId) {
                            setManagerId(undefined);
                            setWantToUpdate(false);
                            return;
                        }
                        setWantToCreate(false);
                    }}
                />
            </Dialog>

            <Dialog
                open={wantToArchive}
                title={ARCHIVE_CONFIRMATION_TITLE}
                message={ARCHIVE_CONFIRMATION_DESCRIPTION}
                btnOk={{
                    label: CONFIRM,
                    isSubmit: archiveMutation.isLoading,
                }}
                btnCancel={{}}
                onClose={() => {
                    setManagerId(undefined);
                    setWantToArchive(false);
                }}
                onCancel={() => {
                    setManagerId(undefined);
                    setWantToArchive(false);
                }}
                onConfirm={handleArchive}
            />
        </div>
    );
};

export default UserOrganisationManagerContainer;

const i18n = [
    "std_add",
    "Arsea.Organisation.Detail.Manager.Update.Dialog.Title",
    "Arsea.Organisation.Detail.Manager.Add.Dialog.Title",
    "Arsea.Organisation.Detail.Manager.Archive.Confirmation.Dialog.Title",
    "Arsea.Organisation.Detail.Manager.Archive.Confirmation.Dialog.Message",
    "std_confirm",
];
