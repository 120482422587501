import { AppRouteProps } from "@arsea/component";
import React from "react";
import { ProjectCalendarCreateRoutes } from "./views/create/ProjectCalendarCreateRoutes";
import { ProjectCalendarEditRoutes } from "./views/edit/ProjectCalendarEditRoutes";
const CalendarContainer = React.lazy(() => import("./container/CalendarContainer"));

export const ProjectCalendarRoutes: Array<AppRouteProps> = [
    {
        path: "calendar",
        title: "Arsea.Vacation.Calendar",
        element: <CalendarContainer id="CalendarContainer" view="timeGridWeek" />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectCalendarCreateRoutes,
            ...ProjectCalendarEditRoutes
        ]
    },
];
