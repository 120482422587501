import { GraphQLQueryClass } from "@arsea/component";
import { TUserManagersResponse } from "@arsea/graphql";

export class UserOrganisationManagerListQuery
    implements GraphQLQueryClass<TUserManagersResponse, TResponse>
{
    queryKey: string = "App.Arsea.Client.User.Organisation.List.Query";

    gql: string = `
        query UserOrganisationManagerListQuery(
            $page: Float
            $pageSize: Float
            $sort: [TFilterSort!]
            $data: JSONObject
        ) {
            userManagers(
                filter: { page: $page, pageSize: $pageSize, sort: $sort, data: $data }
            ) {
                total
                data {
                    id
                    name
                    user {
                        id
                        fullName
                    }
                    users {
                        id
                        fullName
                    }
                    organisation {
                        id
                    }
                    isEditable
                    isDeletable
                }
            }
        }
    `;

    variables?: any;

    mapFn(data: TResponse): TUserManagersResponse {
        return data.userManagers;
    }
}

type TResponse = {
    userManagers: TUserManagersResponse;
};