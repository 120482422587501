import { Box, Button, Chip, Dropdown, IconButton, useNotificationCount, useTranslationRoute, useTranslations } from "@arsea/component"
import { NotificationList } from "@arsea/notification";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NotificationMenu = () => {

    const [
        NOTIFICATIONS,
        SEE_ALL
    ] = useTranslations(i18n)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const notificationCount = useNotificationCount()
    const tr = useTranslationRoute();
    const navigate = useNavigate();

    return (
        <div>
            <div className="relative">
                <IconButton
                    icon={"fa-bell"}
                    className={"text-lg"}
                    onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => setAnchorEl(e.currentTarget)}
                />
                {notificationCount > 0 && (
                    <Chip
                        className="absolute top-0 right-[-5px] bg-primary"
                        label={notificationCount?.toString()}
                    />
                )}
            </div>
            <Dropdown
                anchorEl={anchorEl}
                arrow
                placement="bottom-end"
                onClose={() => setAnchorEl(undefined)}
            >
                <Box className="w-80 p-0 box-transparent">
                    <div className="px-4 py-2 flex items-center justify-between">
                        <h3>{NOTIFICATIONS}</h3>
                        <Button
                            className="underline"
                            onClick={() => {
                                setAnchorEl(undefined);
                                navigate(tr("notification"));
                            }}
                        >
                            {SEE_ALL}
                        </Button>
                    </div>
                    <NotificationList isInNavbar />
                </Box>
            </Dropdown>
        </div>
    );
}

const i18n = [
    'Arsea.Admin.LeftSideBar.Menu.General.Notifications.Label',
    'std_see_all'
]