import { useGraphqlMutation } from "@arsea/component"

export const useArchiveMutation = () => {
    return useGraphqlMutation(gql);
}

const gql = `
    mutation UserOrganisationManagerArchiveMutation($data: JSONObject) {
        saveUserManager(data: { data: $data }) {
            id
        }
    }
`;