import {
    Avatar,
    AvatarGroup,
    Dropdown,
    IconButton,
    Menu,
    useTranslation,
    useTranslationRoute,
    useUserProfilePicture,
} from "@arsea/component";
import { TUserManager } from "@arsea/graphql";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";

const UserOrganisationManagerListItem = (
    props: UserOrganisationManagerListItemProps
) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);

    const profilePictureUrl = useUserProfilePicture();
    const t = useTranslation();
    const tr = useTranslationRoute();

    return (
        <div className="w-full flex justify-between">
            <div className="flex gap-4 items-center">
                <div className="w-12 h-12">
                    <Link to={tr(`user/${props.user?.id}/about`)}>
                        <Avatar
                            size="md"
                            src={profilePictureUrl(props?.user?.id)}
                            fullName={props?.user?.fullName}
                        />
                    </Link>
                </div>
                <div className="flex flex-col gap-2">
                    {props.user && (
                        <div className="flex gap-2">
                            <h3 className="tracking-wider font-bold">
                                <Link to={tr(`user/${props.user?.id}/about`)}>
                                    {props?.user.fullName}
                                </Link>
                                <span> • </span>
                                <span onClick={() => props.onUpdate?.(props.id)} className="font-normal cursor-pointer">{props.name}</span>
                                
                            </h3>
                        </div>
                    )}
                    <AvatarGroup
                        size="xs"
                        avatarItems={props.users?.map((user) => ({
                            pictureId: user.id,
                            title: user.fullName,
                            itemLink: tr(`user/${user?.id}/about`),
                        }))}
                    />
                </div>
            </div>
            <div>
                <IconButton
                    icon="fa-ellipsis-vertical"
                    onClick={(e: MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        setAnchorEl(e.currentTarget)
                    }
                />
                <Dropdown
                    anchorEl={anchorEl}
                    arrow
                    onClose={() => setAnchorEl(undefined)}
                >
                    <Menu
                        items={[
                            { ke: "update", label: t("std_update") },
                            { ke: "archive", label: t("std_archive") },
                        ]}
                        showFn={(menu) => {
                            if (menu.ke === "update") {
                                return props.isEditable as any;
                            }
                            if (menu.ke === "archive") {
                                return props.isDeletable as any;
                            }
                            return false;
                        }}
                        onClick={(menu) => {
                            if (menu.ke === "update") {
                                setAnchorEl(undefined);
                                props.onUpdate?.(props.id);
                                return;
                            }
                            if (menu.ke === "archive") {
                                setAnchorEl(undefined);
                                props.onArchive?.(props.id);
                            }
                        }}
                    />
                </Dropdown>
            </div>
        </div>
    );
};

export default UserOrganisationManagerListItem;

type UserOrganisationManagerListItemProps = Partial<TUserManager> & {
    onUpdate?: (managerId: number) => void;
    onArchive?: (managerId: number) => void;
};
