import { useTranslationRoute } from "@arsea/component";
import { Link } from "react-router-dom";
import { atom } from "recoil";
import { AvatarMenu } from "../menu/AvatarMenu";
import "./css/navbar.css";
import { NotificationMenu } from "./NotificationMenu";

export const AdminNavBar = ({ onToggleClick }: NavBarProps) => {
    const translateRoute = useTranslationRoute();

    return (
        <header
            className={`sticky top-0 flex z-50 justify-between items-center bg-white border-b-4 border-primary text-black py-2 px-4 shadow-md`}
        >
            <div className="flex items-center gap-4">
                <Link to="/">
                    <img
                        src={"/assets/img/logo.png"}
                        alt="Logo Harea"
                        className={`
                        h-10
                    `}
                    />
                </Link>
                {process.env.NODE_ENV === 'development' && (<Link to={translateRoute("translation")}>Traduction</Link>)}
            </div>
            <nav>
                <ul className="flex items-center gap-4 sm:gap-6">
                    <li>
                        <NotificationMenu />
                    </li>

                    <li className="ml-2">
                        <AvatarMenu />
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export type NavBarProps = {
    onToggleClick?: () => void;
};

export const calendarOpenState = atom({
    key: "calendarOpenState",
    default: false,
});