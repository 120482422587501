import { AppRouteProps } from "@arsea/component";
import React from "react";
import { LoginRoutes } from "./view/login/LoginRoutes";
import { ForgotRoutes } from "./view/forgot/ForgotRoutes";

const UserAuthContainer = React.lazy(
    () => import("./container/UserAuthContainer")
);
export const UserAuthRoutesTest: Array<AppRouteProps> = [
    {
        title: "Auth.Title",
        path: "auth",
        element: <UserAuthContainer />,
        children: [...LoginRoutes, ...ForgotRoutes],
    },
];
