import { AppRouteProps } from "@arsea/component";
import { ExportContainer } from "./container/ExportContainer";

export const DataExportRoutes: Array<AppRouteProps> = [
    {
        title: "Arsea.Data.Export.Title",
        path: "export",
        element: <ExportContainer />,
    },
];
