import { AppRouteProps } from '@arsea/component'
import React from 'react'
import { UserListRoutes } from './view/list/UserListRoutes'
import { UserOrganisationRoutes } from './view/organisation/UserOrganisationRoutes'
import { UserDetailsRoutes } from './view/user-details/UserDetailsRoutes'

const AdminUserManagementContainer = React.lazy(() => import("./container/AdminUserManagementContainer"))

export const AdminUserManagementRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <AdminUserManagementContainer />,
        children: [
            ...UserOrganisationRoutes, ...UserListRoutes, ...UserDetailsRoutes
        ]
    }
]
