import { AppRouteProps } from "@arsea/component";
import React from "react";
import { ClientUserDetailRoutes } from "./view/user-detail/ClientUserDetailRoutes";

const ClientUserManagementContainer = React.lazy(
    () => import("./container/ClientUserManagementContainer")
);

export const ClientUserManagementRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <ClientUserManagementContainer />,
        children: [...ClientUserDetailRoutes],
    },
];