import { ControlListProps, useTranslations } from "@arsea/component";

export const useManagerControl = (
    editing: boolean = false
): ControlListProps => {
    const [TEAM, RESPONSIBLE, MEMBERS, SAVE, ADD] = useTranslations(i18n);

    return {
        data: {
            forms: [
                { type: "hidden", name: "id" },
                {
                    type: "text",
                    name: "name",
                    label: TEAM,
                    required: true,
                },
                {
                    type: "select",
                    name: "user",
                    label: RESPONSIBLE,
                    GQLProps: {
                        gql: `
                            query($data: JSONObject) {
                                userGroupInvitations(filter: { data: $data }) {
                                    total
                                    data {
                                        group {
                                            designation
                                        }
                                        user {
                                            id
                                            fullName
                                        }
                                    }
                                }
                            }
                        `,
                        rootProp: "userGroupInvitations.data",
                        labelProp: "user.fullName",
                        valueProp: "user.id",
                        userIdProp: "user.id",
                        variables: {
                            data: {
                                group: {
                                    designation_among: [
                                        "Administrateur",
                                        "Manager",
                                    ],
                                },
                            },
                        },
                    },
                    valueObject: true,
                    valueProperty: "id",
                    required: true,
                },
                {
                    type: "select",
                    name: "users",
                    label: MEMBERS,
                    multiple: true,
                    GQLProps: {
                        gql: `
                            query {
                                users (filter:{ sort: [{ sort: "ASC", value: "fullName" }] }) {
                                    data{
                                        id
                                        fullName
                                    }
                                }
                            }
                        `,
                        rootProp: "users.data",
                        labelProp: "fullName",
                        valueProp: "id",
                        userIdProp: "id",
                    },
                    valueObject: true,
                    valueProperty: "id",
                    required: true,
                },
            ],
            next: {
                label: editing ? SAVE : ADD,
            },
            graphQL: {
                entity: "userManager",
            },
        },
    };
};

const i18n = [
    "Arsea.Organisation.Manager.Team.Label",
    "std_person_in_charge",
    "std_members",
    "std_save",
    "std_add",
];
