import { AppRouteProps } from "@arsea/component";
import React from "react";
import { AboutUserDetailsRoutes } from "views/admin/view/user-details/views/about/AboutUserDetailsRoutes";
import { DevicesUserDetailsRoutes } from "views/admin/view/user-details/views/devices/DevicesUserDetailsRoutes";
import { ParamsUserDetailsRoutes } from "views/admin/view/user-details/views/params/ParamsUserDetailsRoutes";

const UserDetailsContainer = React.lazy(
    () => import("./container/UserDetailsContainer")
);

export const ClientUserDetailRoutes: Array<AppRouteProps> = [
    {
        path: "user/:id",
        title: "Arsea.User.List.Details.title",
        element: <UserDetailsContainer />,
        children: [
            ...AboutUserDetailsRoutes,
            ...ParamsUserDetailsRoutes,
            ...DevicesUserDetailsRoutes,
        ],
        roles: ["USER"],
    },
];
