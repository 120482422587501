import {
    Avatar,
    Dropdown,
    IconButton,
    Menu,
    MenuItemProps,
    useRuntimeEnv,
    useTranslationLang,
    useUserProfilePicture
} from "@arsea/component";
import { useCallback, useState } from "react";
import { useAvatarMenuItems } from "./hooks/useAvatarMenuItems";

export const AvatarMenu = () => {

    const profilePictureUrl = useUserProfilePicture()
    const menuItems = useAvatarMenuItems();
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement>();
    const translationLang = useTranslationLang();
    const { runtimeEnv } = useRuntimeEnv()

    const handleClick = useCallback(
        (item: MenuItemProps) => {
            if (['fr', 'en'].includes(item.ke!)) {
                translationLang.setLang(item.ke as string);
            }
        },
        []
    );
    
    return (
        <div>
            <a
                href="#"
                data-testid="avatar-link"
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <div className="relative">
                    <Avatar
                        src={profilePictureUrl(runtimeEnv?.user?.id)}
                        fullName={runtimeEnv?.user?.fullName}
                        size="sm"
                    />
                    <IconButton
                        icon="fa fa-chevron-down"
                        className="absolute bottom-[-2px] right-[-3px] text-black text-[10px] p-0 bg-white rounded-full border-[1px] border-default w-4 h-4"
                    />
                </div>
            </a>
            <Dropdown
                anchorEl={anchorEl}
                arrow
                placement="bottom-end"
                onClose={() => setAnchorEl(undefined)}
            >
                <Menu name="avatar-menu" items={menuItems} onClick={handleClick} />
            </Dropdown>
        </div>
    );
};

const i18n = ["std_menu"];
