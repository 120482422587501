import { AppRouteProps } from '@arsea/component'
import React from 'react'

const TranslationEdit = React.lazy(() => import('./containers/TranslationEdit'))

export const TranslationEditRoutes: Array<AppRouteProps> = [
    {
        path: 'create',
        title: 'Bo.Translation.Edit.title',
        element: <TranslationEdit />,
        roles: ["TRANSLATION"]

    },
    {
        path: 'edit/:id',
        title: 'Bo.Translation.Edit.title',
        element: <TranslationEdit />,
        roles: ["TRANSLATION"]

    },
    {
        path: 'clone/:id',
        title: 'Bo.Translation.Edit.title',
        element: <TranslationEdit clone />,
        roles: ["TRANSLATION"]

    },
]
