import { AppRouteProps } from "@arsea/component";
import { ImportContainer } from "./container/ImportContainer";

export const DataImportRoutes: Array<AppRouteProps> = [
    {
        title: "Arsea.Data.Import.Title",
        path: "import",
        element: <ImportContainer />,
    },
];
