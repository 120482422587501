import { Button, useListFilterState, useTranslation } from "@arsea/component";

const UserOrganisationManagerListFilter = ({ listId }: { listId: string }) => {
    const { filter, setFilter } = useListFilterState(listId);
    const t = useTranslation();

    return (
        <Button
            className={`btn ${filter.data?.dateArchived_isNull ? "btn-default" : "btn-primary"} !rounded-full`}
            label={t("std_archived")}
            onClick={() =>
                setFilter((prevVal: Record<string, any>) => ({
                    ...prevVal,
                    data: {
                        ...prevVal.data,
                        dateArchived_notIsNull: prevVal.data
                            .dateArchived_notIsNull
                            ? undefined
                            : true,
                        dateArchived_isNull: prevVal.data.dateArchived_isNull
                            ? undefined
                            : true,
                    },
                }))
            }
        />
    );
};

export default UserOrganisationManagerListFilter;
