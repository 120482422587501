import { AppRouteProps } from "@arsea/component";
import React from "react";

const LoginContainer = React.lazy(() => import("./container/LoginContainer"));
export const LoginRoutes: Array<AppRouteProps> = [
    {
        path: "login",
        title: "userAuth.Login.title",
        element: <LoginContainer />,
    },
];
