import { AppRouteProps } from "@arsea/component";
import { AuthRoutes } from "@arsea/login";
import { AdminUserManagementRoutes } from "@arsea/user-management";
import { ProjectRoutes } from "@arsea/project";
import { TranslationRoutes } from "@arsea/translation";
import AdminLayout from "components/layout/AdminLayout";
import AuthLayout from "components/layout/AuthLayout";
import ArseaContainer from "container/ArseaContainer";
import { UserAuthRoutesTest } from "./auth/UserAuthRoutes";
import { HomeRoutes } from "./home/HomeRoutes";
import { NotificationRoutes } from "@arsea/notification";
import NotFound from "../components/NotFound";
import { DataExports } from "./data/DataRoutes";

export const ArseaRoutes: Array<AppRouteProps> = [
    {
        path: ":lang",
        title: `std_home`,
        element: <ArseaContainer />,
        children: [
            {
                path: "",
                element: <AdminLayout />,
                children: [
                    ...HomeRoutes,
                    ...ProjectRoutes,
                    ...NotificationRoutes,
                    ...AdminUserManagementRoutes,
                    ...TranslationRoutes,
                    ...DataExports
                ],
            },
            {
                path: "",
                element: <AuthLayout />,
                children: [
                    ...UserAuthRoutesTest,
                    ...AuthRoutes.filter((item) => item.path === "logout"),
                ],
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
];
