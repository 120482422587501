import { AppRouteProps } from "@arsea/component";
import React from "react";

const ProjectCalendarEditAppointmentContainer = React.lazy(() => import("./container/ProjectCalendarEditAppointmentContainer"));

export const ProjectCalendarEditRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "Arsea.Vacation.Calendar",
        element: <ProjectCalendarEditAppointmentContainer />,
        roles: ["APPOINTMENT"]
    },
];
