import {
    MenuItemProps,
    useRuntimeEnv,
    useRuntimeEnvRolesIncludes,
    useTranslationRoute,
    useTranslations,
} from "@arsea/component";
import { useMemo } from "react";

export const useAvatarMenuItems = () => {
    const { runtimeEnv } = useRuntimeEnv();
    const runtimeEnvRoleIncludes = useRuntimeEnvRolesIncludes();

    const [PROFILE, LOGOUT] = useTranslations(i18n);
    const translationRoute = useTranslationRoute();

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: PROFILE,
                link: translationRoute(`user/${runtimeEnv?.user.id}/about`),
                startIcon: "fa-solid fa-user",
            },
            ...(runtimeEnvRoleIncludes("ORGANISATION")
                ? [
                      {
                          label: runtimeEnv?.organisation?.designation,
                          link: translationRoute(
                              `user/organisation/${runtimeEnv?.organisation?.id}`
                          ),
                          startIcon: "fa-solid fa-building",
                      },
                  ]
                : []),
            {
                divide: true,
            },
            {
                label: LOGOUT,
                "data-testid": "avatar-menu-logout",
                link: translationRoute(`logout`),
                startIcon: "fa-solid fa-right-from-bracket",
            },
        ];
    }, []);
};

const i18n = ["std_profil", "std_logout"];
