import { GenericSuspense } from "@arsea/component";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import BarLoader from 'react-spinners/BarLoader';
import { AdminNavBar as NavBar } from "./AdminNavBar";
import "./css/mainLayout.css";
import NotificationDialog from "components/dialog/NotificationDialog";

const AdminLayout = () => {
    const [openSidebar, setOpenSidebar] = useState(true);

    const toogleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    return (
        <>
            <div className="admin-layout flex w-full">
                <main className="w-full transition-all duration-1000 ease-in-out flex-1 flex flex-col">
                    <NavBar onToggleClick={() => toogleSidebar()} />
                    <GenericSuspense
                        fallback={
                            <BarLoader className='main-spinner-bar-loader shadow-sm' width="100%" />
                        }
                    >
                        <div className="flex items-start w-full p-0 parent-layout">
                            <Outlet />
                        </div>
                    </GenericSuspense>
                </main>
            </div>

            <NotificationDialog />
        </>
    );
};

export default AdminLayout;
