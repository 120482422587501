import { AppRouteProps } from "@arsea/component";
import { DataContainer } from "./containers/DataContainer";
import { DataImportRoutes } from "./view/import/DataImportRoutes";
import { DataExportRoutes } from "./view/export/DataExportRoutes";

export const DataExports: Array<AppRouteProps> = [
    {
        title: "Arsea.Data.Title",
        path: "data",
        element: <DataContainer />,
        children: [...DataImportRoutes, ...DataExportRoutes],
    },
];
