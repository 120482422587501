import { AppRouteProps } from "@arsea/component";
import React from "react";
import { Outlet } from "react-router-dom";
import { UserOrganisationViewRoutes } from "./views/UserOrganisationViewRoutes";

const UserOrganisation = React.lazy(() => import("./containers/UserOrganisation"));
const UserOrganisationEdit = React.lazy(() => import("./containers/UserOrganisationEdit"));
const UserOrganisationDetails = React.lazy(() => import("./containers/UserOrganisationDetails"));
const UserOrganisationCreate = React.lazy(() => import("./containers/UserOrganisationCreate"));
export const UserOrganisationRoutes: Array<AppRouteProps> = [
    {
        path: "user/organisation",
        title: "Generic.UserManagement.Menu.User.Organisation.Title",
        element: <UserOrganisation />,
        noLink: true,
        roles: ["ORGANISATION"]
    },
    {
        path: "user/organisation/create",
        title: "Arsea.UserManagement.Organisation.Create.Title",
        element: <UserOrganisationCreate />,
    },
    {
        path: "user/organisation/:id",
        title: "{{UserOrganisationDesignation}}",
        noLink: true,
        element: <UserOrganisationDetails />,
        children: UserOrganisationViewRoutes,
        roles: ["ORGANISATION"]
    },
];
