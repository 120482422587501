import { AppRouteProps } from "@arsea/component";
import React from "react";
import { ProjectCalendarRoutes } from "views/calendar/CalendarRoutes";

const ProjectContainer = React.lazy(() => import("./containers/ProjectContainer"));

// const PresenceContainer = React.lazy(() => import("./containers/PresenceContainer"));

export const ProjectRoutes: Array<AppRouteProps> = [
    {
        path: "project",
        title: "Arsea.Project",
        element: <ProjectContainer id="ProjectContainer" view="timeGridWeek" />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectCalendarRoutes,
        ]
    }
];
